// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust as needed */
    background-color: #00a693; /* Set the desired background color */
  }

  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .form-input {
    width: 300px;
    height: 30px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .submit-button {
    width: 100px;
    height: 30px;
    background-color: #ff7f50;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .submit-button:hover {
    background-color: #ff7f50;
  }
`, "",{"version":3,"sources":["webpack://./src/components/auth/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,qBAAqB;IACpC,yBAAyB,EAAE,qCAAqC;EAClE;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh; /* Adjust as needed */\n    background-color: #00a693; /* Set the desired background color */\n  }\n\n  .title {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n\n  .form-input {\n    width: 300px;\n    height: 30px;\n    padding: 5px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n\n  .submit-button {\n    width: 100px;\n    height: 30px;\n    background-color: #ff7f50;\n    color: #000000;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n\n  .submit-button:hover {\n    background-color: #ff7f50;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
