import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './components/auth/Login';
import Landing from './components/landing_page/Landing';
import Dashboard from './components/dashboard/Dashboard'; // import Dashboard


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path='/user' element={<Login/>} />
            <Route path='/home' element={<Landing/>} />
            <Route path='/' element={<Landing/>} />
            <Route path='/dashboard' element={<Dashboard />} />
          </Routes>
        </Router>
      </Provider>
    );
  }
}

export default App;
