import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useEffect } from 'react-redux';

import "./Login.css";

class Login extends Component {
  state = {
    username: "",
    password: "",
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.loginUser(userData, this.props.history);
    this.setState({ redirect: true });
  };

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.setState({ redirect: true });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to='/dashboard' />
    }
    return (
      <div className="container">
        <h2 className="title">ASA Taxify User Login</h2>
        <form noValidate onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              onChange={this.onChange}
              value={this.state.username}
              id="username"
              className="form-input"
              type="string"
              placeholder="Username"
            />
          </div>
          <div className="form-group">
            <input
              onChange={this.onChange}
              value={this.state.password}
              id="password"
              className="form-input"
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              Login
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { loginUser })(Login);
