import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { SET_CURRENT_USER, GET_ERRORS } from './types';

export const loginUser = (userData, history) => (dispatch) => {
  axios
    // .get("http://62.106.95.109:8007/api/v1/token", {
    .get("http://asataxify.ir/api/v1/token", {
    // .get("http://127.0.0.1:8000/api/v1/token", {
    // .get("http://127.0.0.1/api/v1/token", {
      headers: {
        accept: "application/json",
        password: userData.password,
        username: userData.username,
      },
      // withCredentials: true,
    })
    .then((res) => {
      // Save to localStorage
      const token = res.data.token_type + ' ' + res.data.access_token;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      history.push('/dashboard'); // redirect to dashboard
      window.location.href = '/dashboard'; // redirect to dashboard
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        // Handle specific error message from the server
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        // Handle generic error
        dispatch({
          type: GET_ERRORS,
          payload: err.response,
        });
      }
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
