import React, { Component } from 'react';

import "../../base.css";


class Dashboard extends Component {
  render() {
    return (
      <div className="container">
        <h1>Welcome to the Dashboard</h1>
      </div>
    );
  }
}

export default Dashboard;
