import React from 'react';

import "../../base.css";

const Dashboard = () => {
  return (
    <div className="container">
      <h1>Welcome to Taxify!</h1>
      <button onClick={() => window.location.href = '/user'} className="submit-button">
        Login
      </button>
    </div>
  );
};

export default Dashboard;
